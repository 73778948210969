.card-body {
	position: relative;
}

.main-statistic {
	line-height: 1.2;
	text-align: center;

	.main-statistic__value {
		display: block;
		font-size: 3em;
	}
}

.statistic__label {
	display: block;
	font-size: 0.8rem;
	color: #999999;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.minor-statistic {
	position: absolute;
	bottom: 0.5em;
	font-size: 1.2em;
	line-height: 1.2;

	span {
		text-align: center;
		display: block;
	}
}

.minor-statistic__left {
	left: 1em;
}

.minor-statistic__right {
	right: 1em;
}
