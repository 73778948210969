/* path indicator styles */

// no arrows for right now
.end-segment {
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		color: #c00;
		transform: translate3d(-70%, 160%, 0);
	}

	&.side-task-path:before {
		border-color: var(--darkGrey);
		transform: translate3d(-85%, 175%, 0);
	}
}
