@import 'normalize.css';
@import 'common/Vars';
@import 'common/Breakpoints';
@import 'common/Colors';
@import 'common/Fonts';
@import 'common/Mixins';
@import 'common/BootstrapOverrides';
@import 'common/Rome-UI';

.navwrapper ~ div {
	margin-top: 116px;
}

@mixin defaults() {
	--darkGrey: #989898;
	--lightGrey: #eee;
	--white: #fff;
	--card-background-color: #fff;
}

body {
	@include defaults();
}

html,
body,
footer {
	height: 100%;
	position: relative;
	font-family: $base-font;
	font-size: $fsTextBody;
	background-color: $background-light;
	color: $text-default;
}

@mixin darkmode() {
	--text-color: #f5f5f5;
	--link-color: #faf697;
	--background-color: #222 !important;
	--card-background-color: #343642;
	--box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
		0 6.7px 5.3px rgba(0, 0, 0, 0.02), 0 12.5px 10px rgba(0, 0, 0, 0.02),
		0 22.3px 17.9px rgba(0, 0, 0, 0.02), 0 41.8px 33.4px rgba(0, 0, 0, 0.046),
		0 100px 80px rgba(0, 0, 0, 0);
	--border: #343642;
	--selected: #494949cf;
	--table-row: #4b4b4b;
	--template-hours: #05505c;
	--template-sidebar: #4b4b4b;
	--modal-background: #343642;
	--scrollbar-background: #6c757d;
	--scrollbar-color: #eee;
}

a {
	color: var(--link-color);
	text-decoration: underline;
	text-decoration-style: dotted;
	text-decoration-color: #ccc;
	text-underline-offset: 1px;
}

hr {
	border-color: $accent;
}

button {
	background-color: #fff;
	border-color: $accent;
}
/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
/* add more elements here to apply custom core-ui box-shadow */

.uppy-DragDrop-label,
.alert p,
.alert svg {
	color: #222 !important;
}

/* add more elements here to apply custom core-ui border */
.container {
	padding-top: 10px;
	padding-bottom: 10px;
}

.check-icon {
	color: #008032 !important;
}

.error-icon {
	color: #ff2223 !important;
}

/* WORKFLOW TEMPLATE DARK / LIGHT STYLES */

.UNxGJ,
.template-container,
.stage-editor {
	color: var(--text-color) !important;
	background-color: var(--table-row) !important;
	background: var(--table-row) !important;
	margin-top: 30px;
}

/* Template sidebar */
.jYVnjb {
	background-color: var(--template-sidebar) !important;
	background: var(--template-sidebar) !important;
}

.frFWtn {
	background-color: #416c65 !important;
	color: #fff !important;
}

/* dropdown items */
a.dropdown-item,
button.dropdown-item,
button.bDyGWT svg {
	background-color: #fff;
	color: #222 !important;

	svg {
		background-color: #fff;
		color: #222;
	}
}

p.hours {
	color: var(--template-hours) !important;
}

.react-calendar__tile--now {
	background-color: $text-default !important;
	&:hover {
		background-color: lighten($text-default, 0.7) !important;
	}
}

.react-calendar {
	border: none;
	border-radius: 0.25rem;
	width: 100% !important;
	background-color: var(--card-background-color) !important;
	color: var(--text-color) !important;
}

.react-datepicker-wrapper {
	width: 100%;
}

.terms-and-conditions {
	text-align: center;
	margin-left: 109rem;
	// Extra small devices (portrait phones, less than 576px)
	@media (max-width: 575.98px) {
		text-align: center;
		margin-left: 20rem;
	}

	// Small devices (landscape phones, 576px and up)
	@media (min-width: 576px) and (max-width: 767.98px) {
		text-align: center;
		margin-left: 30rem;
	}

	// Medium devices (tablets, 768px and up)
	@media (min-width: 768px) and (max-width: 991.98px) {
		text-align: center;
		margin-left: 50rem;
	}

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) and (max-width: 1199.98px) {
		text-align: center;
		margin-left: 50rem;
	}

	// Extra large devices (large desktops, 1200px and up)
	@media (min-width: 1200px) and (max-width: 1500px) {
		text-align: center;
		margin-left: 74rem;
	}
}
