$black: #000000;
$darkestGrey: #404040;
$darkerGrey: #6c757d;
$darkGrey: #afafaf;
$grey: #dbdbdb;
$lightGrey: #ebebeb;
$lighterGrey: #f0f0f0;
$lightestGrey: #f5f5f5;
$white: #ffffff;

/* Default core-ui */
$background-dark: #383030;
$accent: #b7b4a0;

$primaryColor: #686453;
$primaryColorLight: lighten($primaryColor, 50%);
$primaryColorDark: darken($primaryColor, 50%);

$secondaryColor: #4f4746;
$secondaryColorLight: lighten($secondaryColor, 67%);
$secondaryColorDark: darken($secondaryColor, 50%);
$secondaryHighlighted: $secondaryColorDark;

$background-light: lighten($primaryColor, 55%);
$text-default: $primaryColor;
$link-default: #2f6781;
$inactive: lighten($primaryColor, 0.7);

$dangerColor: #cc0000;

/*
$tertiaryColor:
$tertiaryColorLight:
$tertiaryColorDark:
*/

/*
DEFAULT COLOR SCHEME
Set base scheme here. Sass vars don't work for setting root color vars?
*/

:root {
	--link-color: #{$link-default};
}

// export vars for js consumption
:export {
	black: $black;
	darkestGrey: $darkestGrey;
	darkerGrey: $darkerGrey;
	darkGrey: $darkGrey;
	grey: $grey;
	lightGrey: $lightGrey;
	lighterGrey: $lighterGrey;
	lightestGrey: $lightestGrey;
	white: $white;
}
